import store from '../../store/index'


export default {
    open(callback=() => {}, promoter){
        store.commit('modalEditarPromoter',{
            display: true,
            promoter: promoter,
            callbackOK: callback
        })
    },
    close(){
        store.commit('modalEditarPromoter', {
            display: false,
            promoter: {},
            callbackOK: () => {}
        });
    },
}
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from '../store/index'
import alert from './services/alert'
import modal from './services/modal'
import modalIngresso from './services/modalIngresso'
import modalPromoter from './services/modalPromoter'
import modalconfirmation from './services/modalconfirmation'
import modalLogout from './services/modalLogout'
import modalSucessoSuporte from './services/modalSucessoSuporte'
import modalTrocaEventos from './services/modalTrocaEventos'
import loading from './services/loading'
import evento from './services/evento'
import account from './services/account'
import filtro from './services/filter'
import './assets/styles/index.css'
import './assets/styles/icons.css'
import './index.css'

import { vMaska } from "maska"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueQrcode from 'vue-qrcode';
library.add(fas, far)

import headerDropDown from './services/headerDropDown'
import VueApexCharts from 'vue3-apexcharts'
import modalValorPago from './services/modalValorPago'
import modalPagamento from './services/modalPagamento'
import modalCadastroPromoters from './services/modalCadastroPromoters'
import modalVincularPromoter from './services/modalVincularPromoter'
import modalEditarPromoter from './services/modalEditarPromoter'
import modalVincularPromoterOutroEvento from './services/modalVincularPromoterOutroEvento'
import modalVisualizarEmissao from './services/modalVisualizarEmissao'
import modalPromoterMovimentacaoGeral from './services/modalPromoterMovimentacao'
import modalPromoterMovimentacao from './services/modalPromoterMovimentacao'
import modalConvidarColaborador from './services/modalConvidarColaborador'
import modalCadastroPainel from './services/modalCadastroPainel'
import modalPagamentoPix from './services/modalPagamentoPix'
import modalVendaPix from './services/modalVendaPix'
import modalAcessarApp from './services/modalAcessarApp'
import modalConfirmarEmissao from './services/modalConfirmarEmissao'
import modalLiberarIngresso from './services/modalLiberarIngresso'
import modalExcluirPromoterPermanente from './services/modalExcluirPromoterPermanente'
import modalDesvincularPromoter from './services/modalDesvincularPromoter'
import modalRealizarPagamentoPromoter from './services/modalRealizarPagamentoPromoter'
import modalExcluirEmissao from './services/modalExcluirEmissao'
import modalCancelarHistoricoEmissao from './services/modalCancelarHistoricoEmissao'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

const app = createApp(App);

const head = document.getElementsByTagName('head')[0];
const hotjarScript = document.createElement('script');
hotjarScript.type = 'text/javascript';
hotjarScript.innerHTML = `
(function(h,o,t,j,a,r){
  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  h._hjSettings={hjid:4959500,hjsv:6};
  a=o.getElementsByTagName('head')[0];
  r=o.createElement('script');r.async=1;
  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
`;
head.appendChild(hotjarScript);

app
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(FloatingVue, {
      themes: {
        tooltip: {
          placement: 'bottom-end',
          tooltipClass: 'custom-tooltip',
        },
      },
    })
    .component('fa', FontAwesomeIcon)
    .component('qrcode', VueQrcode)
    .component('apexchart', VueApexCharts)
    .provide('alert', alert)
    .provide('loading', loading)
    .provide('modal', modal)
    .provide('modalconfirmation', modalconfirmation)
    .provide('modalLogout', modalLogout)
    .provide('modalSucessoSuporte', modalSucessoSuporte)
    .provide('modalTrocaEvento', modalTrocaEventos)
    .provide('modalIngresso', modalIngresso)
    .provide('modalPromoter', modalPromoter)
    .provide('modalValorPago', modalValorPago)
    .provide('modalPagamento', modalPagamento)
    .provide('modalCadastroPromoters', modalCadastroPromoters)
    .provide('modalVincularPromoter', modalVincularPromoter)
    .provide('modalEditarPromoter', modalEditarPromoter)
    .provide('modalVincularPromoterOutroEvento', modalVincularPromoterOutroEvento)
    .provide('modalVisualizarEmissao', modalVisualizarEmissao)
    .provide('modalPromoterMovimentacaoGeral', modalPromoterMovimentacaoGeral)
    .provide('modalPromoterMovimentacao', modalPromoterMovimentacao)
    .provide('modalConvidarColaborador', modalConvidarColaborador)
    .provide('modalCadastroPainel', modalCadastroPainel)
    .provide('modalPagamentoPix', modalPagamentoPix)
    .provide('modalVendaPix', modalVendaPix)
    .provide('modalAcessarApp', modalAcessarApp)
    .provide('modalConfirmarEmissao', modalConfirmarEmissao)
    .provide('modalLiberarIngresso', modalLiberarIngresso)
    .provide('modalExcluirPromoterPermanente', modalExcluirPromoterPermanente)
    .provide('modalDesvincularPromoter', modalDesvincularPromoter)
    .provide('modalRealizarPagamentoPromoter', modalRealizarPagamentoPromoter)
    .provide('modalExcluirEmissao', modalExcluirEmissao)
    .provide('modalCancelarHistoricoEmissao', modalCancelarHistoricoEmissao)
    .provide('evento', evento)
    .provide('account', account)
    .provide('filtros', filtro)
    .provide('headerDropdown', headerDropDown)
    .directive("maska", vMaska)
    .mount('#app')

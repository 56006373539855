import { useRouter } from 'vue-router';
import store from '../../store/index'
import { ref } from 'vue';

export default {

    getAccount() {
        const router = useRouter();

        let account = store.getters.account;

        const infoLocalStorage = localStorage.getItem('info_meu_evento');

        if (infoLocalStorage) {
            const infoUser = JSON.parse(infoLocalStorage)

            this.setAccount({
                id: infoUser.id,
                email: infoUser.email,
                admin: infoUser.admin
            })
        }

        account = store.getters.account

        if (account.id == 0) {
            localStorage.clear();
            router.push('/login')
        }


        return account
    },

    setAccount(value) {
        store.commit('setAccount', {
            id: value.id,
            email: value.email,
            admin: value.admin
        })
    },

    getEvento() {
        return store.getters.evento
    },

    getEventoID() {
        return store.getters.evento
    },

    setEvento(value) {
        store.commit('setEvento', {
            id: value.id,
            nome: value.nome,
            data: value.data,
            data_inicio: value.data_inicio,
            data_fim: value.data_fim,
        })
    },

    verifyEvento() {
        const ls = localStorage.getItem('event_santo_cartao')

        if (ls !== "null") {
            const eventLS = JSON.parse(ls!)
            store.commit('setEvento', {
                id: eventLS.id,
                nome: eventLS.nome,
                data: eventLS.data
            })

        } else {
            store.commit('setEvento', {
                id: 0,
                nome: "Selecione um evento",
                data: new Date().toLocaleDateString('pt-BR'),
                year: ""
            })
        }
        return this.getEventoID()
    },

   
}


const EventoInfo = {

    getEvento() {

        const ls = localStorage.getItem('event_santo_cartao')

        if (ls !== "null") {
            const eventLS = JSON.parse(ls!)
            store.commit('setEvento', {
                id: eventLS.id,
                nome: eventLS.nome,
                data: eventLS.data,
                data_inicio: eventLS.data_inicio,
                data_fim: eventLS.data_fim,
            })

        } else {
            store.commit('setEvento', {
                id: 0,
                nome: "Selecione um evento",
                data: new Date().toLocaleDateString('pt-BR'),
                year: "",
                data_inicio: '',
                data_fim: '',
            })
        }

        return store.getters.evento
    },

    getEventoID() {

        const ls = localStorage.getItem('event_santo_cartao')

        if (ls !== "null") {
            const eventLS = JSON.parse(ls!)
            store.commit('setEvento', {
                id: eventLS.id,
                nome: eventLS.nome,
                data: eventLS.data,
                data_inicio: eventLS.data_inicio,
                data_fim: eventLS.data_fim,
            })

        } else {
            store.commit('setEvento', {
                id: 0,
                nome: "Selecione um evento",
                data: new Date().toLocaleDateString('pt-BR'),
                year: "",
                data_inicio: '',
                data_fim: '',
            })
        }
        return store.getters.evento.id
    },

    setEvento(value) {
        store.commit('setEvento', {
            id: value.id,
            nome: value.nome,
            data: value.data,
            data_inicio: value.data_inicio,
            data_fim: value.data_fim,
        })
    },

    verifyEvento() {
        const ls = localStorage.getItem('event_santo_cartao')

        if (ls !== "null") {
            const eventLS = JSON.parse(ls!)
            store.commit('setEvento', {
                id: eventLS.id,
                nome: eventLS.nome,
                data: eventLS.data,
                data_inicio: eventLS.data_inicio,
                data_fim: eventLS.data_fim,
            })

        } else {
            store.commit('setEvento', {
                id: 0,
                nome: "Selecione um evento",
                data: new Date().toLocaleDateString('pt-BR'),
                year: "",
                data_inicio: '',
                data_fim: '',
            })
        }
        return this.getEventoID()
    },

    saveOriginalFilters(filters: any){
        const filtros: any  = ref([])
        for(const key in filters){
            filters[key].data.map((item: any) => {
                const obj={
                    id: item.id,
                    nome: item.nome,
                    parent: filters[key].nome
                }

                if(filters[key].id != 0){
                    filtros.value.push(obj)
                }
            })
        }

        window.localStorage.setItem('original_filters', JSON.stringify(filtros.value))
        
    },

    getOriginalFilters(){
       return JSON.parse(window.localStorage.getItem('original_filters')!)
    }
}

export { EventoInfo }
<template>
  <div v-if="store.getters.modalDesvincularPromoter.display" x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
    <!-- Modal -->
    <div x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      class="w-full lg:h-1/2 h-full px-4 pt-4 pb-14 bg-gray-200 rounded-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
      role="dialog" id="modal">

      <div class="bg-white rounded-lg h-full w-full overflow-auto">
        <div class="flex flex-col justify-center items-center">
          <div class="text-md text-SC_Azul text-center p-5 mt-3">

            <div class="flex justify-center mb-3">
              <img src="../../assets/images/imagemDesvincularPromoter.png" width="200" height="200" />
            </div>

            <span class="font-normal"> Você realmente deseja desvincular o(s) promoter(s):


              <div v-if="promoterV.length >= 1">
                <p v-for="(promoter, index) in promoterV" :key="index" class="title1 font-bold text-SC_Azul my-3"> {{
                  promoter.promoter }} - (<span :class="{ 'colorGreen': promoter.saldo >= 0, 'colorRed': promoter.saldo < 0 }">{{ formatarMonetarioBRL(promoter.saldo) }}</span>)
                </p>
              </div>

              <p v-else class="title1 font-bold text-SC_Azul my-3"> {{ promoterV.value.promoter }} - 
                ({{ formatarMonetarioBRL(promoterV.value.saldo) }})
              </p>

              do evento <span class="font-bold"> {{ eventoNome }} </span>
            </span>
          </div>
        </div>
      </div>
      <div class="flex justify-between mt-2">
        <button class="botaoCancelar" @click="close">Cancelar</button>
        <button class="botaoConfirmar" @click="confirmar">Confirmar</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { ref, watch, inject } from 'vue';
import moment from "moment-timezone";

export default {
  setup() {
    const store = useStore();
    const promoterV = ref()
    const eventoInfo = inject("evento") as any;
    const eventoID = eventoInfo.getEventoID()
    const eventoNome = ref('')

    eventoNome.value = eventoInfo.getEvento().nome


    const close = () => {
      store.commit("modalDesvincularPromoter", { display: false, promoter: {}, callbackOK: () => { } });
    };


    watch(
      () => [store.getters.modalDesvincularPromoter.display],
      () => {
        if (store.getters.modalDesvincularPromoter.display) {
          promoterV.value = store.getters.modalDesvincularPromoter.promoter
          if (!promoterV.value.length)
            promoterV.value = [promoterV.value]
        } else {
          promoterV.value = {}
        }
      })

    const confirmar = async () =>  {
      store.getters.modalDesvincularPromoter.callbackOK();
    };

    function formatarMonetarioBRL(valor) {
            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
            }).format(valor);
        }


    return { store, close, promoterV, eventoID, eventoNome, confirmar, formatarMonetarioBRL };
  },
}
</script>

<style scoped>
.botaoCancelar {
  border: 1px solid #361D54;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
}

.botaoCancelar:hover {
  background-color: #361D54;
  color: white;
}

.botaoConfirmar {
  border: 1px solid red;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
  background-color: red;
  color: white;
}

.botaoConfirmar:hover {
  background-color: rgb(156, 41, 41);
  color: white;
}

.campoDados {
  color: #5E5E5E;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  padding: 3px 0px 3px 15px;
}

.resumoEmissao {
  border: 1px solid #E4E3E3;
  background-color: #FBFCFD;
  color: #8E8E8E;
}

.img {
  width: 150px;
  height: 150px;
}

.colorGreen {
  color: green;
}

.colorRed {
  color: red;
}
</style>
<template>
  <div v-if="store.getters.modalRetiradaConcluida.display" x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed inset-0 flex bg-black bg-opacity-50 items-center justify-center sairOverlay">
    <div x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      class="modalConcluido px-6 py-4 bg-white rounded-t-lg dark:bg-gray-800 rounded-lg sm:m-4 sm:w-auto flex flex-col justify-evenly items-center z-50 gap-5"
      role="dialog">
      <p class="text-SC_Title font-bold text-SC_Azul">Solicitação concluída</p>
      <p class="text-SC_Gray text-SC_Text">
        O prazo para a conclusão desta solicitação é de até <b>3 dias úteis.</b> As transferências são realizadas entre
        <b>15h e 16h</b>.
      </p>
      <div class="flex flex-col items-start justify-start w-full">
        <div
          class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3 w-full">
          <p class="text-SC_Gray text-SC_Text font-semibold">Valor:</p>
          <p class="text-SC_Text text-SC_Gray">
            {{ valor }}
          </p>
        </div>

        <div
          class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3 w-full">
          <p class="text-SC_Gray text-SC_Text font-semibold">Para:</p>
          <p class="text-SC_Text text-SC_Gray">
            {{ retirada.to.apelido || retirada.to.nome_titular }}
          </p>
        </div>

        <div
          class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3 w-full">
          <p class="text-SC_Gray text-SC_Text font-semibold">
            Nome do Titular:
          </p>
          <p class="text-SC_Text text-SC_Gray">
            {{ retirada.to.nome_titular }}
          </p>
        </div>

        <div
          class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3 w-full"
          v-if="retirada.to.tipo_pessoa == 'f'">
          <p class="text-SC_Gray text-SC_Text font-semibold">CPF:</p>
          <p class="text-SC_Text text-SC_Gray">
            {{ retirada.to.cpf_cnpj }}
          </p>
        </div>
        <div
          class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3 w-full"
          v-else>
          <p class="text-SC_Gray text-SC_Text font-semibold">CNPJ:</p>
          <p class="text-SC_Text text-SC_Gray">
            {{ retirada.to.cpf_cnpj }}
          </p>
        </div>

        <div
          class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3 w-full">
          <p class="text-SC_Gray text-SC_Text font-semibold">Instituição:</p>
          <p class="text-SC_Text text-SC_Gray">
            {{ retirada.to.banco }}
          </p>
        </div>

        <div
          class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3 w-full">
          <p class="text-SC_Gray text-SC_Text font-semibold">Agência:</p>
          <p class="text-SC_Text text-SC_Gray">
            {{ retirada.to.agencia }}
          </p>
        </div>

        <div
          class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3 w-full">
          <p class="text-SC_Gray text-SC_Text font-semibold">Conta:</p>
          <p class="text-SC_Text text-SC_Gray">
            {{ retirada.to.numero_conta }} -
            {{ retirada.to.numero_conta_digito }}
          </p>
        </div>

        <div
          class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3 w-full">
          <p class="text-SC_Gray text-SC_Text font-semibold">Tipo de Conta:</p>
          <p class="text-SC_Text text-SC_Gray">
            {{
              retirada.to.tipo_conta == "cc"
                ? "Conta Corrente"
                : "Conta Poupança"
            }}
          </p>
        </div>
        <div
          class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3 w-full"
          v-if="retirada.to.chave_pix ">
          <p class="text-SC_Gray text-SC_Text font-semibold">Chave PIX</p>
          <p class="text-SC_Text text-SC_Gray">
            {{ retirada.to.chave_pix }}
          </p>
        </div>
        <div
          class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3 w-full"
          v-if="retirada.to.descricao">
          <p class="text-SC_Gray text-SC_Text font-semibold">Descrição:</p>
          <p class="text-SC_Text text-SC_Gray">
            {{ retirada.to.descricao }}
          </p>
        </div>
      </div>
      <button class="bg-SC_Roxo text-white rounded-md px-5 py-2 mt-4 cursor-pointer continue" id="continueBtn"
        @click="closeAll">
        Concluir
      </button>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "SolicitacaoConcluida",
  props: {
    retirada: {
      type: Object,
      required: true,
    },
    valor: {
      type: String,
      required: true,
    },
    closeFunction: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const closeAll = () => {

      props.closeFunction();


      store.commit("modalRetiradaConcluida", {
        display: false,
      });
      store.commit("modalRetirada", {
        display: false,
      });
    };

    return {
      store,
      closeAll,
    };
  },
};
</script>

<style lang="scss" scoped>
.modalConcluido {
  max-width: 500px;
}

.sairOverlay {
  z-index: 99999999999999;
}
</style>

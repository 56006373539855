<template>
  <div v-if="store.getters.modalAcessarApp.display" x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
    <!-- Modal -->
    <div x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      class="w-full lg:h-3/4 h-full px-4 pt-4 pb-14 bg-gray-200 rounded-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
      role="dialog" id="modal">

      <div class="bg-white flex flex-col gap-10 justify-center items-center rounded-lg h-full w-full overflow-auto">
        <h1 class="text-center text-SC_Gray font-bold" style="font-size: 19px;"> Leia o QR code usando o APP</h1>
        <div class="h-72 w-72 rounded-md border-2 border-SC_Azul" v-if="flag">
          <qrcode :value="qrcode" class="h-full w-full" />
        </div>
        <div v-else>
          <a href="https://play.google.com/store/apps/details?id=com.santocartao.leitorSantoCartao" target="_blank">
            <img src="@/assets/images/leituras/google-play.png" />
          </a>
          <a href="https://apps.apple.com/br/app/check-in-santo-cart%C3%A3o/id1418662570" target="_blank">
            <img src="@/assets/images/leituras/appstore.png" />
          </a>
        </div>


      </div>
      <div class="flex justify-center mt-2">
        <button class="botaoFechar" @click="close">Fechar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, watch, inject } from 'vue';
import { GET } from "@/services/api";
import { EventoInfo } from "@/services/geral";


export default {
  setup() {
    const store = useStore();
    const qrcode = ref(null);
    const flag = ref(false)
    const evento = EventoInfo.getEvento();
    const user = ref(null);

    const getCheckin = async () => {
      const res = { ...(await GET(`/event/${evento.id}`)) };
      let check;

      if (res.evento_usuario_perfil) {
        check = res.evento_usuario_perfil.filter((el) => el.id_perfil === 5);
        if (check[0]?.usuario?.usuario) {
          flag.value = true;
          user.value = check[0].usuario.usuario;
          qrcode.value = `login=${user.value};password=sc123`;
        } else {
          flag.value = false;
        }
      }
    };

    const close = () => {
      store.commit("modalAcessarApp", { display: false });
    };


    watch(
      () => [store.getters.modalAcessarApp.display],
      async () => {
        if (store.getters.modalAcessarApp.display)
          await getCheckin()
      })

    return { store, close, flag, qrcode, user };
  },
}
</script>

<style scoped>
.botaoFechar {
  border: 1px solid #361D54;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
}

.botaoFechar:hover {
  background-color: #361D54;
  color: white;
}

.botaoConfirmar {
  border: 1px solid #E95D88;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
  background-color: #E95D88;
  color: white;
}

.botaoConfirmar:hover {
  background-color: #b94a6b;
  color: white;
}

.campoDados {
  color: #5E5E5E;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  padding: 3px 0px 3px 15px;
}

.resumoEmissao {
  border: 1px solid #E4E3E3;
  background-color: #FBFCFD;
  color: #8E8E8E;
}

.img {
  width: 210px;
  height: 210px;
}
</style>
<template>
  <div v-if="store.getters.modalPromoterMovimentacao.display" x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
    <!-- Modal -->
    <div x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      class=" lg:w-3/4 w-full lg:h-3/4 h-full px-4 pt-4 pb-14 bg-gray-200 rounded-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 "
      role="dialog" id="modal">

      <div class="bg-white rounded-lg h-full w-full overflow-auto">
        <div class="flex flex-col">
          <div class="title1 text-SC_Azul text-center p-5 mt-3">
            <h1>Movimentações do promoter - {{ promoterV.promoter }}</h1>

            <div class="flex flex-col w-full mt-5">
              <div class="overflow-auto w-full text-left">
                <DatatableAgGrid :rows="dadosPromoterMovimentacao" :columns="headersPromoterMovimentacao"
                  :defaultColDef="defaultColDef" :type="'PromoterMovimentacao'" />
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="flex flex-row justify-center mt-2 noprint">
        <button class="botaoFechar" @click="close">Fechar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, watch, inject, onMounted } from 'vue';
import moment from "moment-timezone";
import DatatableAgGrid from "@/components/Elements/dataTable/DatatableAgGrid.vue";
import { formatarMonetarioBRL, formatarDataIsoData, formatarData } from "@/services/formater";
import { GET } from "@/services/api";

export default {
  components: {
    DatatableAgGrid
  },
  setup() {
    const store = useStore();
    const promoterV = ref()
    const eventoInfo = inject("evento")
    const eventoNome = ref('')
    const tipoBotao = ref('')
    const nomePromoter = ref('')
    const total = ref({})

    const close = () => {
      store.commit("modalPromoterMovimentacao", { display: false, promoter: {}, callbackOK: () => { } });
    };

    const confirmar = async () => {
      store.getters.modalPromoterMovimentacao.callbackOK();
    };

    watch(
      () => [store.getters.modalPromoterMovimentacao.display],
      async () => {
        if (store.getters.modalPromoterMovimentacao.display) {
          promoterV.value = store.getters.modalPromoterMovimentacao.promoter
          nomePromoter.value = promoterV.value.promoter
          dadosPromoterMovimentacao.value = []

          total.value = {
            data: 'Total',
            produto: 'Todos',
            quantidade: 0,
            valor: 0,
            nomePromoter: nomePromoter.value
          }

          const response = await GET(`promoter_movements/${promoterV.value.id_promoter}/historico/${eventoInfo.getEventoID()}/evento`)

          response.data.map((item) => {
            total.value.quantidade += parseInt(item.quantidade)
            total.value.valor += parseFloat(item.devido);

            dadosPromoterMovimentacao.value.push(
              {
                data: formatarDataIsoData(item.data),
                produto: item.produto,
                quantidade: parseInt(item.quantidade),
                valor: parseFloat(item.devido),
                nomePromoter: nomePromoter.value
              }
            )
          })
          dadosPromoterMovimentacao.value.push(total.value)

        } else {
          promoterV.value = {}
        }
      })


    const headersPromoterMovimentacao = ref([
    {
        field: 'data',
        headerName: 'Data evento',
        filter: 'agDateColumnFilter',
        minWidth: 100,
        filterParams: {
          maxNumConditions: 1,
          filterPlaceholder: 'Filtrar por Data do evento...',
        },
        valueFormatter: (params) => {
          if (params.value !== 'Total') {
            return formatarData(params.value)
          }
          return 'Total'
        },
        cellClass: 'wrap-text leading-6 flex items-center',
        cellStyle: params => {
          return { color: 'black', fontWeight: 600 };
        }
      },
      {
        field: 'produto',
        headerName: 'Produto',
        minWidth: 100,
        filter: 'agTextColumnFilter',
        cellClass: 'wrap-text leading-6 flex items-center',
        filterParams: {
          maxNumConditions: 1,
          filterPlaceholder: 'Filtrar por Produto...',
        },
      },
      {
        field: 'quantidade',
        headerName: 'Quantidade',
        filter: 'agNumberColumnFilter',
        minWidth: 100,
        cellClass: 'wrap-text leading-6 flex items-center',
        filterParams: {
          maxNumConditions: 1,
          filterPlaceholder: 'Filtrar por Quantidade...',
        },
      },
      {
        field: 'valor',
        headerName: 'Valor devido',
        filter: 'agNumberColumnFilter',
        minWidth: 100,
        cellClass: 'wrap-text leading-6 flex items-center',
        valueFormatter: (params) => formatarMonetarioBRL(params.value),
        filterParams: {
          maxNumConditions: 1,
          filterPlaceholder: 'Filtrar por Valor...',
        },
      },

    ]);

    const dadosPromoterMovimentacao = ref([

    ]);

    const defaultColDef = ref({
      editable: false,
      filter: false,
      flex: 1,
      autoHeight: true,
      autoSize: true,
      cellClass: 'items-center',
      suppressMovable: true,
      suppressMenu: true,
    });

    eventoNome.value = eventoInfo.getEvento().nome


    return { store, close, eventoNome, promoterV, confirmar, tipoBotao, headersPromoterMovimentacao, defaultColDef, dadosPromoterMovimentacao };
  },
}
</script>

<style scoped>
.botaoFechar {
  border: 1px solid #361D54;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
}

.botaoFechar:hover {
  background-color: #361D54;
  color: white;
}

.botaoConfirmar {
  border: 1px solid #408135;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
  background-color: #408135;
  color: white;
}

.botaoConfirmar:hover {
  background-color: #2a5523;
  color: white;
}

@media print {
  .noprint {
    display: none !important;
  }

  .print-only {
    display: block !important;
  }
}
</style>